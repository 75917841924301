import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Button, FormField, SidebarModal, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useServiceJobDetail from '@hooks/useServiceJobDetail'

const defaultState = {
  templateList: '',
  valid: false,
}

const AssetTemplateOverrideModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { templateList } = state

  const { details } = useContext(PageContext)

  const {
    callbacks: {
      updateServiceJobDetail: updateFn,
    },
    updating,
  } = useServiceJobDetail()

  useEffect(() => {
    if (details) {
      let copyTemplateList = details.find(x => x?.entityFieldType?.name === "CopyTemplateList")?.value || ''
      setState({ templateList: JSON.parse(copyTemplateList).value?.toString(), valid: true })
    }
  }, [])

  const saveOverride = () => {
    const pattern = /^[0-9]+(,[0-9]+)*$/
    if (!pattern.test(templateList)) {
      return toast.warning('Invalid format')
    }

    let copyTemplateListId = details.find(x => x?.entityFieldType?.name === "CopyTemplateList")?.id

    const payload = {
      id: copyTemplateListId,
      value: `{\"value\":[${templateList}]}`,
    }

    updateFn(payload).then(({ success, errors }) => {
      if (!success) toast.warning(errors[0])
    })
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Template" titleSecondLine="Override" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" alignItems="center" width="100%">
          <Box flexDirection="column" padding="large">
            <Text fontSize="small" color="bodyFontLightColor" marginBottom="large" lineHeight={1.3}>
              Note: You need to regenerate the asset after saving the template override for the changes to take effect.
            </Text>
            <FormField
              boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
              label="Template"
            >
              <input name="name" type="text" pattern="^[0-9,]*$" value={templateList} onChange={e => setState({ templateList: e.target.value })} required />
              <Button
                buttonStyle="primaryCreate"
                disabled={!templateList.length}
                loading={updating}
                marginLeft="auto"
                marginTop="medium"
                onClick={() => saveOverride()}
                size="medium"
                width="auto"
              >
                Save
              </Button>
            </FormField>
          </Box>
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetTemplateOverrideModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetTemplateOverrideModal.defaultProps = {
  modalKey: 'AssetTemplateOverrideModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetTemplateOverrideModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
